import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V1.webp";
import Image2 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V2.webp";
import Image3 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V3.webp";
import Image4 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V4.webp";
import Image5 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V5.webp";
import Image6 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V6.webp";
import Image7 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V7.webp";
import Image8 from "../../../../Product/Casual/Jackets_Vest_Cas/J&V8.webp";

const JacketsVestCas = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

  return (
    <ProductGallery
      images={images}
      category={{ english: "casual", enName: "Casual", arabic: "كاجوال", arName: "كاجوال" }}
      product={{ english: "Jackets & Vest", arabic: "جاكيتات وسترات" }}
    />
  );
};

export default JacketsVestCas;

import React from "react";
import "./HomeS5P2.css";
import HomeS5P2Img2 from "../../../Assets/Svg/HomeS5P2Img2.svg";
// import HomeS5P2Img4 from "../../../Assets/Svg/HomeS5P2Img4.svg";
// import HomeS5P2Img5 from "../../../Assets/Svg/HomeS5P2Img5.svg";
import HomeS5P2Img6 from "../../../Assets/Svg/HomeS5P2Img6.svg";
// import HomeS5P2Img7 from "../../../Assets/Svg/HomeS5P2Img7.svg";
// import HomeS5P2Img8 from "../../../Assets/Svg/HomeS5P2Img8.svg";
import HomeS5P2Img9 from "../../../Assets/Svg/HomeS5P2Img9.svg";
import HomeS5P2Img10 from "../../../Assets/Svg/HomeS5P2Img10.svg";
import HomeS5P2Img11 from "../../../Assets/Svg/HomeS5P2Img11.svg";
import HomeS5P2Img12 from "../../../Assets/Svg/HomeS5P2Img12.svg";
import HomeS5P2Img13 from "../../../Assets/Svg/HomeS5P2Img13.svg";
import { useLocation } from "react-router-dom";
export default function HomeS5P2() {
  const Location = useLocation();
  const isArabic = Location.pathname.endsWith("/ar");
  return (
    <>
      {isArabic ? (
        <>
          <div className="HomeS5Web">
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img2} H3Text="التعليم" />
              <HomeS5P2C Image={HomeS5P2Img10} H3Text="الطبي" />
              <HomeS5P2C Image={HomeS5P2Img9} H3Text="الشركات" />
              <HomeS5P2C Image={HomeS5P2Img6} H3Text="الصناعة" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img13} H3Text="الضيافة" />
              <HomeS5P2C Image={HomeS5P2Img11} H3Text="كاجوال" />
              <HomeS5P2C Image={HomeS5P2Img12} H3Text="الرياضية" />
            </div>
          </div>
          {/*  */}
          <div className="HomeS5Mob">
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img2} H3Text="التعليم" />
              <HomeS5P2C Image={HomeS5P2Img10} H3Text="الطبي" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img9} H3Text="الشركات" />
              <HomeS5P2C Image={HomeS5P2Img6} H3Text="الصناعة" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img13} H3Text="الضيافة" />
              <HomeS5P2C Image={HomeS5P2Img11} H3Text="كاجوال" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img12} H3Text="الرياضية" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="HomeS5Web">
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img2} H3Text="EDUCATIONAL" />
              <HomeS5P2C Image={HomeS5P2Img10} H3Text="MEDICAL" />
              <HomeS5P2C Image={HomeS5P2Img9} H3Text="CORPORATE" />
              <HomeS5P2C Image={HomeS5P2Img6} H3Text="INDUSTRIAL" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img13} H3Text="HOSPITALITY" />
              <HomeS5P2C Image={HomeS5P2Img11} H3Text="CASUAL" />
              <HomeS5P2C Image={HomeS5P2Img12} H3Text="SPORTS WEAR" />
            </div>
          </div>
          {/*  */}
          <div className="HomeS5Mob">
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img2} H3Text="EDUCATIONAL" />
              <HomeS5P2C Image={HomeS5P2Img10} H3Text="MEDICAL" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img9} H3Text="CORPORATE" />
              <HomeS5P2C Image={HomeS5P2Img6} H3Text="INDUSTRIAL" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img13} H3Text="HOSPITALITY" />
              <HomeS5P2C Image={HomeS5P2Img11} H3Text="CASUAL" />
            </div>
            <div className="HomeS5P2">
              <HomeS5P2C Image={HomeS5P2Img12} H3Text="SPORTS WEAR" />
            </div>
          </div>
        </>
      )}
    </>
  );
}
function HomeS5P2C({ Image, H3Text }) {
  return (
    <>
      <div className="HomeS5P2C" dir="rtl">
        <img src={Image} alt="" className="HomeS5P2C_Img" />
        <h3 className="HomeS5P2C_H3 ar">{H3Text}</h3>
      </div>
    </>
  );
}

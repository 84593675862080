import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Visibility_Jacket/VJ1.webp";
import Image2 from "../../../../Product/Industrial/Visibility_Jacket/VJ2.webp";
import Image3 from "../../../../Product/Industrial/Visibility_Jacket/VJ3.webp";
import Image4 from "../../../../Product/Industrial/Visibility_Jacket/VJ4.webp";
import Image5 from "../../../../Product/Industrial/Visibility_Jacket/VJ5.webp";
import Image6 from "../../../../Product/Industrial/Visibility_Jacket/VJ6.webp";

const VisibilityJacket = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Visibility jacket", arabic: "جاكيتات عاكسة" }}
    />
  );
};

export default VisibilityJacket;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Hospitality/Chef_Suit/CS1.webp";
import Image2 from "../../../../Product/Hospitality/Chef_Suit/CS2.webp";
import Image3 from "../../../../Product/Hospitality/Chef_Suit/CS3.webp";
import Image4 from "../../../../Product/Hospitality/Chef_Suit/CS4.webp";
import Image5 from "../../../../Product/Hospitality/Chef_Suit/CS5.webp";
import Image6 from "../../../../Product/Hospitality/Chef_Suit/CS6.webp";

const ChefSuit = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "hospitality", enName: "Hospitality", arabic: "الضيافة", arName: "الضيافة" }}
      product={{ english: "Chef Suit", arabic: "بدلات الطهاة" }}
    />
  );
};

export default ChefSuit;

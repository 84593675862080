import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./Header.css";
import Logo from "../../Assets/Svg/Logo.svg";
import SocialMediaIcons from "../StickySocialMedia/SocialMediaIcons";
import ButtonContact from "../ButtonContact/ButtonContact";

export default function EnglishHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;
    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
  };

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div className="Header_Main_C">
        <div className="Header_Main_Container">
          <div className="Header_Container">
            <SocialMediaIcons />
            <div className="Logo">
              <Link to="/">
                <img src={Logo} alt="Logo" className="Header_Logo" />
              </Link>
            </div>
            <div className="Menu">
              <Link to="/" className={`Menu_Text ${isActive("/") ? "active" : ""}`}>
                Home
              </Link>
              <Link to="/about-us" className={`Menu_Text ${isActive("/about-us") ? "active" : ""}`}>
                About Us
              </Link>
              <Link to="/Categories" className={`Menu_Text ${isActive("/gallery") ? "active" : ""}`}>
                Categories
              </Link>
              <Link to="/media" className={`Menu_Text ${isActive("/media") ? "active" : ""}`}>
                Media
              </Link>
            </div>
            <div className="Language">
              <div className="Header_Languages" onClick={() => setShowDropdown(!showDropdown)}>
                <div className="Header_Languages_Main">
                  <h4 className="Language_Dropdown_Text" onClick={handleLanguageChange}>
                    AR
                  </h4>
                  {/* <KeyboardArrowDownIcon style={{ color: "#b84031" }} /> */}
                </div>
                {/* {showDropdown && (
                  <div className="Language_Dropdown">
                    <button onClick={handleLanguageChange} className="Language_Dropdown_Text">
                      Ar
                    </button>
                  </div>
                )} */}
              </div>
              <div>
                <ButtonContact />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Graduation-Gown/GG1.webp";
import Image2 from "../../../../Product/School_Uniforms/Graduation-Gown/GG2.webp";
import Image3 from "../../../../Product/School_Uniforms/Graduation-Gown/GG3.webp";
import Image4 from "../../../../Product/School_Uniforms/Graduation-Gown/GG4.webp";
import Image5 from "../../../../Product/School_Uniforms/Graduation-Gown/GG5.webp";

const GraduationGown = () => {
  const images = [Image1, Image2, Image5, Image3, Image4, Image5];
  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Graduation Gown", arabic: "ثوب التخرج" }}
    />
  );
};

export default GraduationGown;

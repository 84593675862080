import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Shirts/BS1N.webp";
import Image2 from "../../../../Product/School_Uniforms/Shirts/BS1.webp";
import Image3 from "../../../../Product/School_Uniforms/Shirts/BS2.webp";
import Image4 from "../../../../Product/School_Uniforms/Shirts/BS3.webp";
import Image5 from "../../../../Product/School_Uniforms/Shirts/BS4.webp";
import Image6 from "../../../../Product/School_Uniforms/Shirts/BS5.webp";

const ShirtsSchool = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Shirts", arabic: "قمصان" }}
    />
  );
};

export default ShirtsSchool;

import React from "react";
import "./Home.css";
import ButtonContact from "../../Components/ButtonContact/ButtonContact";
import HomeS1Img from "../../Assets/Images/HomeS1.webp";
import HomeS2ImgLoop from "../../Components/Home/HomeS2ImgesLoop/HomeS2ImagesLoop";
import HomeS3P2C1L1 from "../../Assets/Svg/Home_S3_P2_C1_L1.svg";
import HomeS3P2C1L2 from "../../Assets/Svg/Home_S3_P2_C1_L2.svg";
import HomeS3P2C1L3 from "../../Assets/Svg/Home_S3_P2_C1_L3.svg";
import HomeS3P2C1L4 from "../../Assets/Svg/Home_S3_P2_C1_L4.svg";
import HomeS3P2C from "../../Components/Home/HomeS3P2C/HomeS3P2C";
import HomeS4P2Img1 from "../../Assets/Images/HomeS4_P2_Img1.webp";
import HomeS4P2Img2 from "../../Assets/Images/HomeS4_P2_Img2.webp";
import HomeS4P2Img3 from "../../Assets/Images/HomeS4_P2_Img3.webp";
import HomeS5P2 from "../../Components/Home/HomeS5P2/HomeS5P2";
import HomeS6ImgRN from "../../Assets/Images/HomeS6ImgL.webp";
import HomeS6ImgL from "../../Assets/Svg/HomeS6P1B-B.svg";
import HomeS7P1ImgN from "../../Assets/Images/HomeS7P1Img.webp";
import HomeS7P2ImgB from "../../Assets/Svg/HomeS7P2B-B.svg";
// import TestimonialHomeS8 from "../../Components/TestimonialHome/TestimonialHome";

import ContactSection from "../../Components/ContactSection/ContactSection";

export default function HomeContent() {
  return (
    <>
      <div className="Home_Container">
        <HomeS1 />
        <HomeS2 />
        <HomeS3 />
        <HomeS4 />
        <HomeS5 />
        <HomeS6 />
        <HomeS7 />
        {/* <HomeS8 /> */}
        <HomeS9 />
      </div>
    </>
  );
}
//
function HomeS1() {
  return (
    <>
      <div className="HomeS1">
        <div className="HomeS1_Container">
          <div className="HomeS1_P1">
            <h3 className="HomeS1_P1_H3">Crafting premium uniforms</h3>
            <h1 className="HomeS1_P1_H1">Dress for Success. Empower Your Team with Confidence.</h1>
            <img src={HomeS1Img} alt="" className="HomeS1ImgMob" />
            <p className="HomeS1_P1_P">Enhance your brand with professional uniforms that leaves a lasting impression.</p>
          </div>
          <div>
            <ButtonContact />
          </div>
        </div>
        <div className="HomeS1_P2">
          <img src={HomeS1Img} alt="HomeS1Img" className="HomeS1Img" />
        </div>
      </div>
    </>
  );
}
//
function HomeS2() {
  return (
    <>
      <div className="HomeS2C">
        <div className="HomeS2">
          <h3 className="HomeS2_P1_H3">
            Over then <span>+20</span> success story
          </h3>
          <h1 className="HomeS2_P1_H1">Our Clients</h1>
          <HomeS2ImgLoop />
        </div>
      </div>
    </>
  );
}

function HomeS3() {
  return (
    <>
      <div className="HomeS3">
        <div className="HomeS3_P1">
          <h1 className="HomeS3_P1_H1">our value</h1>
          <p className="HomeS3_P1_P">At Uniform Arena, we're passionate about creating custom-designed uniforms that </p>
        </div>
        <div className="HomeS3_P2">
          {/*  */}
          <div className="HomeS3_P2_C">
            {/* Box 1 */}
            <HomeS3P2C
              Image={HomeS3P2C1L1}
              H1Text="Customer Focused"
              PText="We collaborate with our customers to understand their specific needs and create tailored uniforms or attire to improve their professional image and employee satisfaction."
            />
            {/* Box 2 */}
            <HomeS3P2C
              Image={HomeS3P2C1L2}
              H1Text="Passion"
              PText="At Uniform Arena, we're passionate about manufacturing custom-designed uniforms that perfectly reflect the spirit and identity of your institution."
            />
          </div>
          {/*  */}
          <div className="HomeS3_P2_C">
            {/* Box 3 */}
            <HomeS3P2C
              Image={HomeS3P2C1L3}
              H1Text="Excellence"
              PText="We manufacture refined uniforms that solves industry challenges. We are always pushing boundaries and investing in new technologies to have an excellent effect."
            />
            {/* Box 4 */}
            <HomeS3P2C
              Image={HomeS3P2C1L4}
              H1Text="INNOVATION"
              PText="Our team is constantly exploring new technologies to develop uniforms that are not only functional but also comfortable and fashionable."
            />
          </div>
        </div>
      </div>
    </>
  );
}
//
function HomeS4() {
  return (
    <>
      <div className="HomeS4">
        <div className="HomeS4_P1">
          <h1 className="HomeS4_P1_H1">Explore Our Collections</h1>
          <div className="HomeS4_P2_Mob">
            <img src={HomeS4P2Img1} alt="HomeS4P2Img1" className="HomeS4P2Img" />
            <img src={HomeS4P2Img2} alt="HomeS4P2Img2" className="HomeS4P2Img" />
            <img src={HomeS4P2Img3} alt="HomeS4P2Img3" className="HomeS4P2Img" />
          </div>
          <p className="HomeS4_P1_P">
            Discover our wide variety of uniforms, carefully designed to meet the needs of schools and institutions. From traditional to modern styles.
          </p>
          <a href="/Categories" className="HomeS4_P1_A">
            <button className="HomeS4_P1_B">Explore Our Categories</button>
          </a>
        </div>
        <div className="HomeS4_P2">
          <img src={HomeS4P2Img1} alt="HomeS4P2Img1" className="HomeS4P2Img" />
          <img src={HomeS4P2Img2} alt="HomeS4P2Img2" className="HomeS4P2Img" />
          <img src={HomeS4P2Img3} alt="HomeS4P2Img3" className="HomeS4P2Img" />
        </div>
      </div>
    </>
  );
}

function HomeS5() {
  return (
    <>
      <div className="HomeS5">
        <div className="HomeS5_P1">
          <h1 className="HomeS5_P1_H1">We Provide UNIFORMS VARIETIES for various INDUSTRIES</h1>
        </div>
        <div>
          <HomeS5P2 />
        </div>
      </div>
    </>
  );
}

function HomeS6() {
  return (
    <>
      <div className="HomeS6">
        <div className="HomeS6_P1">
          <div className="HomeS6P1ImgC">
            <img src={HomeS6ImgL} alt="" className="HomeS6P1ImgB" />
          </div>
          <h5>At Uniform Arena</h5>
          <h1>Tailored to Perfection</h1>
          <div className="HomeS6_P2 HomeS6_P2_Mob">
            <img src={HomeS6ImgRN} alt="HomeS6ImgR" className="HomeS6ImgR" />
          </div>
          <p className="HomeS6_P1_P">
            Our team of experienced designers understands the importance of customization. <br /> Whether you're looking for specific
            <span> colors</span>, <span>logos</span>, or
            <span>special fabrics</span>, we work closely with you to bring your vision to life and make your uniforms stand out. <br /> Every detail is
            meticulously considered to ensure your uniforms are as unique as your brand.
          </p>
        </div>
        <div className="HomeS6_P2 HomeS6_P2_Web">
          <img src={HomeS6ImgRN} alt="HomeS6ImgR" className="HomeS6ImgR" />
        </div>
      </div>
    </>
  );
}
function HomeS7() {
  return (
    <>
      <div className="HomeS7">
        <div className="HomeS7P1">
          <div className="HomeS7P1Img HomeS7P1Img_Web">
            <img src={HomeS7P1ImgN} alt="HomeS7P1Img" className="HomeS7P1Image" />
          </div>
        </div>
        <div className="HomeS7P2">
          <img src={HomeS7P2ImgB} alt="" className="HomeS7P2ImgB" />
          <h3>At Uniform Arena </h3>
          <h1>Quality You Can Trust</h1>
          <div className="HomeS7P1Img HomeS7P1Img_Mob">
            <img src={HomeS7P1ImgN} alt="HomeS7P1Img" className="HomeS7P1Image" />
          </div>
          <p>
            At Uniform Arena, quality is our top priority. We source only the finest fabrics materials and apply highest quality control measures to ensure that
            every garment meets our high standards. <br /> From durability to comfort, our uniforms are built to withstand the demands of daily wear.
          </p>
        </div>
      </div>
    </>
  );
}

// function HomeS8() {
//   return <>{/* <TestimonialHomeS8 /> */}</>;
// }

function HomeS9() {
  return (
    <>
      <ContactSection />
    </>
  );
}

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb1.webp";
import Image2 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb2.webp";
import Image3 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb3.webp";
import Image4 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb4.webp";
import Image5 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb5.webp";
import Image6 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb6.webp";
import Image7 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb7.webp";
import Image8 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb8.webp";
import Image9 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb9.webp";

const TopsBottomsSport = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9];

  return (
    <ProductGallery
      images={images}
      category={{ english: "sports", enName: "Sports wear", arabic: "الرياضية", arName: "الرياضية" }}
      product={{ english: "Tops & Bottoms", arabic: "ملابس علوية وسفلية" }}
    />
  );
};

export default TopsBottomsSport;

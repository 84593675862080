import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/waistcoat/WC1.webp";
import Image2 from "../../../../Product/School_Uniforms/waistcoat/WC2.webp";
import Image3 from "../../../../Product/School_Uniforms/waistcoat/WC3.webp";
import Image4 from "../../../../Product/School_Uniforms/waistcoat/WC4.webp";
import Image5 from "../../../../Product/School_Uniforms/waistcoat/WC5.webp";
import Image6 from "../../../../Product/School_Uniforms/waistcoat/WC6.webp";

const Waistcoat = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Waistcoat", arabic: "صديري" }}
    />
  );
};

export default Waistcoat;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Vest/IV1.webp";
import Image2 from "../../../../Product/Industrial/Vest/IV2.webp";
import Image3 from "../../../../Product/Industrial/Vest/IV3.webp";
import Image4 from "../../../../Product/Industrial/Vest/IV4.webp";
import Image5 from "../../../../Product/Industrial/Vest/IV5.webp";
import Image6 from "../../../../Product/Industrial/Vest/IV6.webp";

const IndustrialVest = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Vest", arabic: "سترات صناعية" }}
    />
  );
};
export default IndustrialVest;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T1.webp";
import Image2 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T2.webp";
import Image3 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T3.webp";
import Image4 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T4.webp";
import Image5 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T5.webp";
import Image6 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T6.webp";
import Image7 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T7.webp";
import Image8 from "../../../../Product/Corporate/Skirts&Trousers-Cor/S&T8.webp";

const SkirtsTrousersCor = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

  return (
    <ProductGallery
      images={images}
      category={{ english: "corporate", enName: "Corporate", arabic: "الشركات", arName: "الشركات" }}
      product={{ english: "Skirts & Trousers", arabic: "تنانير وبناطيل" }}
    />
  );
};
export default SkirtsTrousersCor;

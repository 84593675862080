import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Import unique icons for each item
import { ReactComponent as Icon1 } from "../../Svg/HomeS5P2Img2.svg";
import { ReactComponent as Icon2 } from "../../Svg/HomeS5P2Img13.svg";
import { ReactComponent as Icon3 } from "../../Svg/HomeS5P2Img12.svg";
import { ReactComponent as Icon4 } from "../../Svg/HomeS5P2Img6.svg";
import { ReactComponent as Icon5 } from "../../Svg/HomeS5P2Img10.svg";
import { ReactComponent as Icon6 } from "../../Svg/HomeS5P2Img11.svg";
import { ReactComponent as Icon7 } from "../../Svg/HomeS5P2Img9.svg";

import "./choice.css";

const ChoiceButton = ({ choice, isSelected, onClick }) => {
  const SVGComponent = choice.Icon;
  const Location = useLocation();
  const isArabic = Location.pathname.endsWith("/ar");

  return (
    <>
      {isArabic ? (
        <button className={`choice_button_Ar Ar ${isSelected ? "selected" : ""}`} onClick={onClick} type="button" name="user_choices">
          <SVGComponent className={`choice-svg ${isSelected ? "active" : ""}`} />
          {choice.label}
        </button>
      ) : (
        <button className={`choice-button ${isSelected ? "selected" : ""}`} onClick={onClick} type="button" name="user_choices">
          <SVGComponent className={`choice-svg ${isSelected ? "active" : ""}`} />
          {choice.label}
        </button>
      )}
    </>
  );
};

const Choices = ({ onChoicesChange }) => {
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const Location = useLocation();
  const isArabic = Location.pathname.endsWith("/ar");

  const choices = isArabic
    ? [
        { id: 1, label: "التعليم", Icon: Icon1 },
        { id: 2, label: "الطبي ", Icon: Icon2 },
        { id: 3, label: "كاجوال ", Icon: Icon3 },
        { id: 4, label: "الصناعة", Icon: Icon4 },
        { id: 5, label: "الضيافة", Icon: Icon5 },
        { id: 6, label: "الرياضية", Icon: Icon6 },
        { id: 7, label: "الشركات", Icon: Icon7 },
      ]
    : [
        // Array for desktop screen (>=768px)
        { id: 1, label: "EDUCATIONAL ", Icon: Icon1 },
        { id: 2, label: "HOSPITALITY ", Icon: Icon2 },
        { id: 3, label: "SPORTS WEAR ", Icon: Icon3 },
        { id: 4, label: "INDUSTRIAL ", Icon: Icon4 },
        { id: 5, label: "MEDICAL ", Icon: Icon5 },
        { id: 6, label: "CASUAL ", Icon: Icon6 },
        { id: 7, label: "CORPORATE ", Icon: Icon7 },
      ];
  // const choices = [
  //   {
  //     id: 1,
  //     label: "OFFICE UNIFORMS",
  //     Icon: Icon1,
  //   },
  //   {
  //     id: 2,
  //     label: "SCHOOL UNIFORMS",
  //     Icon: Icon2,
  //   },
  //   {
  //     id: 3,
  //     label: "CORPORATE UNIFORMS",
  //     Icon: Icon3,
  //   },
  //   {
  //     id: 4,
  //     label: "INDUSTRIAL UNIFORMS",
  //     Icon: Icon4,
  //   },
  //   { id: 5, label: "FACTORY UNIFORM", Icon: Icon5 },
  //   { id: 6, label: "MAINTENANCE UNIFORM", Icon: Icon6 },
  //   { id: 7, label: "CONSTRUCTION WORKER UNIFORM", Icon: Icon7 },
  //   { id: 8, label: "SECURITY UNIFORMS", Icon: Icon8 },
  //   { id: 9, label: "CLEANING UNIFORM", Icon: Icon9 },
  //   { id: 10, label: "MEDICAL UNIFORMS", Icon: Icon10 },
  //   { id: 11, label: "T-SHIRT PRINTING", Icon: Icon11 },
  //   { id: 12, label: "CUSTOM EMBROIDERY", Icon: Icon12 },
  //   { id: 13, label: " HOTEL STAFF UNIFORM", Icon: Icon13 },
  // ];

  const toggleChoice = (choiceId) => {
    const choice = choices.find((c) => c.id === choiceId);

    const isCurrentlySelected = selectedChoices.some((c) => c.id === choiceId);

    const newSelectedChoices = isCurrentlySelected ? selectedChoices.filter((c) => c.id !== choiceId) : [...selectedChoices, choice];

    setSelectedChoices(newSelectedChoices);

    onChoicesChange(newSelectedChoices);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="choices-container">
      {choices.map((choice) => (
        <React.Fragment key={choice.id}>
          <ChoiceButton choice={choice} isSelected={selectedChoices.some((c) => c.id === choice.id)} onClick={() => toggleChoice(choice.id)} />
          <input type="hidden" name="user_choices" value={selectedChoices.some((c) => c.id === choice.id) ? choice.label : ""} style={{ display: "none" }} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Choices;

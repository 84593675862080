import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Hospitality/Chef_Coat/CC1.webp";
import Image2 from "../../../../Product/Hospitality/Chef_Coat/CC2.webp";
import Image3 from "../../../../Product/Hospitality/Chef_Coat/CC3.webp";
import Image4 from "../../../../Product/Hospitality/Chef_Coat/CC4.webp";
import Image5 from "../../../../Product/Hospitality/Chef_Coat/CC5.webp";
import Image6 from "../../../../Product/Hospitality/Chef_Coat/CC6.webp";
import Image7 from "../../../../Product/Hospitality/Chef_Coat/CC7.webp";
import Image8 from "../../../../Product/Hospitality/Chef_Coat/CC8.webp";

const ChefCoat = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

  return (
    <ProductGallery
      images={images}
      category={{ english: "hospitality", enName: "Hospitality", arabic: "الضيافة", arName: "الضيافة" }}
      product={{ english: "Chef Coat", arabic: "معاطف الطهاة" }}
    />
  );
};

export default ChefCoat;

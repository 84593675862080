import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB1.webp";
import Image2 from "../../../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB2.webp";
import Image3 from "../../../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB3.webp";
import Image4 from "../../../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB4.webp";
import Image5 from "../../../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB5.webp";
import Image6 from "../../../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB6.webp";

const VisibilityHiVisBib = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Visibility Hi Vis Bib", arabic: "مريولات عاكسة" }}
    />
  );
};
export default VisibilityHiVisBib;

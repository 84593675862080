import React from "react";
import "./About.css";
import AboutS1P1BIcon from "../../Assets/Svg/AboutS1P1BIcon.svg";
import AboutS2P2L from "../../Assets/Images/AboutS2P2L.webp";
import AboutS2P2R from "../../Assets/Images/AboutS2P2R.webp";
import CompanyProfile from "../../Assets/PDF/Company_Profile.pdf";
import CompanyCatalogue from "../../Assets/PDF/Company_Catalogue.pdf";
import ContactSection from "../../Components/ContactSection/ContactSection";
export default function AboutArContent() {
  return (
    <>
      <div className="About_Container">
        <AboutS1 />
        <AboutS2 />
        <AboutS3 />
      </div>
    </>
  );
}
function AboutS1() {
  return (
    <div className="AboutS1Ar" dir="rtl">
      <div className="AboutS1P1Main">
        <div className="AboutS1P1 ar">
          <h5>ماذا عنا</h5>
          <h1>التميّز في كل تفصيلة</h1>
          <div className="AboutS1P3">
            <a href={CompanyProfile} download="Company_Profile.pdf" className="HomeS4_P1_A">
              <button className="AboutS4_P1_B ar">
                تحميل واجهة شركتنا
                <img src={AboutS1P1BIcon} alt="" />
              </button>
            </a>
            <a href={CompanyCatalogue} download="Company_Catalogue.pdf" className="HomeS4_P1_A">
              <button className="AboutS4_P1_B ar">
                تحميل الكتالوج التعليمي الخاص بنا
                <img src={AboutS1P1BIcon} alt="" />
              </button>
            </a>
          </div>
        </div>
        <div className="AboutS1P2C">
          <div className="AboutS1P2 ar">
            <span>يونيفورم أرينا</span> علامة تجارية قطرية للزي الرسمي تابعة لمجموعة العوضي التي تأسست في عام 2016 في قطر. <br /> <br /> قمنا بإطلاق خط الزي
            القطري بنجاح باستخدام أفضل الآلات المتوفرة في السوق مع أحدث التقنيات للحفاظ على أعلى معايير الجودة وتحقيق رضا العملاء... نستخدم معرفتنا الواسعة في
            صناعة الأزياء لإنتاج ملابس أنيقة وتوفير زي موحد فريد للمدارس لتمكينها من تزويد طلابها من خلال تصنيع الملابس المخصصة... نحرص دائمًا على ضمان أفضل
            ملاءمة وجودة، مع التركيز على تلبية احتياجات عملائنا ورضاهم.
          </div>
        </div>
      </div>
    </div>
  );
}

function AboutS2() {
  return (
    <>
      <div className="AboutS2" dir="rtl">
        <div className="AboutS2P1 ar">
          <h1>تميز في الجودة وأزياء رسمية تترك أثراً.</h1>
        </div>
        <div className="AboutS2P2">
          <div className="AboutS2P2B ar">
            <h1>مهمتنا</h1>
            <p>تصنيع الزي الرسمي والملابس، طبقاً للمعايير الدولية، من خلال تحسين الجودة، واستخدام أحدث التقنيات، والابتكار المستمر.</p>
            <img src={AboutS2P2L} alt="" className="AboutS2P2Img" />
          </div>
          <div className="AboutS2P2B ar">
            <h1>رؤيتنا</h1>
            <p>
              الحفاظ على مكانة ريادية وتسويق قيادي طويل الأمد في سوق تصنيع الملابس الصناعية وقطاع الزي الرسمي الذي تتنافس فيه الشركة، يتم تحقيق ذلك من خلال
              الكفاءة التشغيلية، والاهتمام المستمر برعاية العملاء، والمراعاة في التكلفة، والابتكار.
            </p>
            <img src={AboutS2P2R} alt="" className="AboutS2P2Img" />
          </div>
        </div>
      </div>
    </>
  );
}
function AboutS3() {
  return (
    <>
      <ContactSection />
    </>
  );
}

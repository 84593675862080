import React from "react";
import "./Gallery.css";
import AboutS1P1BIcon from "../../Assets/Svg/AboutS1P1BIcon.svg";
import LineSpace from "../../Assets/Svg/Line_Space.svg";
import GalleryS3Com from "../../Components/GalleryS3/GalleryS3";
import CompanyCatalogue from "../../Assets/PDF/Company_Catalogue.pdf";
import ContactSection from "../../Components/ContactSection/ContactSection";

export default function GalleryArContent() {
  return (
    <>
      <GalleryS1 />
      {/* <GalleryS2 /> */}
      <GalleryS3 />
      <GalleryS4 />
    </>
  );
}

function GalleryS1() {
  return (
    <div className="GalleryS1" dir="rtl">
      <div className="GalleryS1_P1 ar Cont">
        <h1>الفئات</h1>
        <p>
          مرحبًا بكم في معرض الزيّ الرسمي في يونيفورم أرينا! اكتشفوا مجموعة من أفضل إبداعات الزيّ الرسمي، التي تجمع بين الجودة، والأسلوب، والمرونة في التصاميم
        </p>
      </div>
      <div className="GalleryS1_P1 ar GalleryS1_P1_Mob">
        <h3>تنزيل الكتيب الرقمي لعرض تشكيلاتنا من الزيّ الرسمي.</h3>
        <a href={CompanyCatalogue} download="Company_Catalogue.pdf" className="HomeS4_P1_A">
          <button className="GalleryS1_B ar">
            قم بتحميل الكتالوج
            <img src={AboutS1P1BIcon} alt="" />
          </button>
        </a>
      </div>
    </div>
  );
}

// function GalleryS2() {
//   return (
//     <div className="GalleryS2">
//       <div className="GalleryS2_Space">
//         <img src={LineSpace} alt="" />
//       </div>
//     </div>
//   );
// }

function GalleryS3() {
  return (
    <>
      <div className="GalleryS3Com">
        <div className="GalleryS3T ar">اختر المجال الخاص بك</div>
        <GalleryS3Com />
      </div>
    </>
  );
}

function GalleryS4() {
  return <ContactSection />;
}

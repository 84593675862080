import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Sports-Wear/1-SW10.webp";
import Image2 from "../../../../Product/School_Uniforms/Sports-Wear/2-SW9.webp";
import Image3 from "../../../../Product/School_Uniforms/Sports-Wear/3-0SW11.webp";
import Image4 from "../../../../Product/School_Uniforms/Sports-Wear/4-SW12.webp";
import Image5 from "../../../../Product/School_Uniforms/Sports-Wear/SW1.webp";
import Image6 from "../../../../Product/School_Uniforms/Sports-Wear/SW2.webp";
import Image7 from "../../../../Product/School_Uniforms/Sports-Wear/SW3.webp";
import Image8 from "../../../../Product/School_Uniforms/Sports-Wear/SW4.webp";
import Image9 from "../../../../Product/School_Uniforms/Sports-Wear/SW5.webp";
import Image10 from "../../../../Product/School_Uniforms/Sports-Wear/SW6.webp";
import Image11 from "../../../../Product/School_Uniforms/Sports-Wear/SW7.webp";
import Image12 from "../../../../Product/School_Uniforms/Sports-Wear/SW8.webp";

const SportsWear = () => {
  const images = [Image5, Image6, Image7, Image8, Image1, Image2, Image3, Image4, Image9, Image10, Image11, Image12];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Sports Wear", arabic: "ملابس رياضية" }}
    />
  );
};

export default SportsWear;

import React from "react";
import MailIcon from "../../Assets/Svg/mail.svg";
import { Link } from "react-router-dom";
import "./ButtonContact.css";

export default function ButtonContactAr() {
  return (
    <button className="Contact_Button ar" dir="rtl">
      <Link to="/contact-us/ar" className="Contact_Text">
        اتصل بنا
      </Link>
      <img src={MailIcon} alt="Mail Icon" />
    </button>
  );
}

import React, { useEffect, useState, useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import Choices from "../../Assets/Hooks/Contact_Phone/choice";
import ContactS2Img from "../../Assets/Images/Contact_S2_P1_R.webp";
import ContactS1Img from "../../Assets/Svg/Contact-S1.svg";

export default function ContactArContent() {
  return (
    <>
      <div className="Contact_Container">
        <ContactS1 />
        <ContactS2 />
      </div>
    </>
  );
}
function ContactS1() {
  const form = useRef();
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isSuccess, setIsSuccess] = useState(false); // Success message state

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const handleChoicesChange = (choices) => {
    setSelectedChoices(choices);
  };

  const validateInputs = (formData) => {
    const name = formData.get("user_name").trim();
    const email = formData.get("user_email").trim();
    const phone = formData.get("user_phone").trim();
    const company = formData.get("user_company").trim();
    const project = formData.get("user_job").trim();

    if (name.length < 3) {
      alert("Please enter at least 3 characters for your name.");
      return false;
    }

    if (!email.includes("@")) {
      alert("Please enter a valid email address.");
      return false;
    }

    if (phone.length < 3) {
      alert("Please enter a valid phone number .");
      return false;
    }

    if (company.length < 3) {
      alert("Please enter at least 3 characters for your company name.");
      return false;
    }

    if (project.length < 3) {
      alert("Please enter at least 3 characters for the project description.");
      return false;
    }

    return true;
  };

  const sendEmailAfterVerification = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    if (!validateInputs(formData)) return;

    const choicesString = selectedChoices.map((choice) => `ID: ${choice.id}, Label: ${choice.label}`).join("; ");
    formData.append("user_choices", choicesString);

    setIsCaptchaOpen(true); // Show captcha pop-up
  };

  const verifyCaptchaAndSendEmail = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      return;
    }

    setIsLoading(true); // Start loading

    try {
      await emailjs.sendForm("service_91m6tox", "template_k6n3zt8", form.current, "wbSWHpfsXfZPUFMj9");

      setIsSuccess(true); // Show success message
      setIsLoading(false); // Stop loading
      setCaptcha(""); // Clear captcha input
      setUserCaptchaInput(""); // Clear user input
      generateCaptcha(); // Generate a new captcha

      if (form.current) {
        form.current.reset(); // Reset the form
      }
    } catch (error) {
      console.error("EmailJS Error: ", error);
      alert("Failed to send email. Please try again later.");
      setIsLoading(false); // Stop loading on error
    }
  };

  return (
    <>
      <div className="Contact_S1" dir="rtl">
        <div className="ContactS1Ab">
          <img src={ContactS1Img} alt="" className="" />
        </div>
        {/* Form content remains the same */}
        <div className="Contact_S1_P1 ar">
          <h1>لنتواصل.</h1>
          <h1>نحن نود مساعدتك.</h1>
          <p> يرجى ملء النموذج أدناه وسنعاود الاتصال بك قريبًا.</p>
        </div>
        <div className="Contact_S1_P2">
          <form ref={form} onSubmit={sendEmailAfterVerification}>
            <div className="contact_part2_second">
              <div className="con_part2_Input1">
                <div className="con_part2_Input1_Chi">
                  <label className="input">
                    <input className="input__field ar input_1" type="text" placeholder="اسمك" name="user_name" required />
                  </label>
                  <label className="input">
                    <input className="input__field ar input_1" type="text" placeholder="بريدك الإلكتروني" name="user_email" required />
                  </label>
                </div>
              </div>
              <div className="con_part2_Input1">
                <div className="con_part2_Input1_Chi">
                  <label className="input">
                    <input className="input__field ar input_1" type="text" placeholder="رقم هاتفك" name="user_phone" required />
                  </label>
                  <label className="input">
                    <input className="input__field ar input_1" type="text" placeholder="اسم الشركة" name="user_company" required />
                  </label>
                </div>
              </div>
              <div className="con_part2_Input1">
                <label className="input">
                  <input className="input__field2 ar input_1" type="text" placeholder="نبذة عن المشروع *" name="user_job" required />
                </label>
              </div>
            </div>
            <div className="contact_part2_third">
              <div className="contact_part2_third_s1">
                <div className="solutionQuestion ar">
                  نوع الزي الرسمي الذي تهتم به <span>(اختياري)</span>
                </div>
                <div className="solutionChoices">
                  <Choices onChoicesChange={handleChoicesChange} />
                </div>
                <div className="solutionSubmit">
                  <button className="Contact_submitButton ar" type="submit">
                    إرسال
                  </button>
                </div>
              </div>
              {/* <div className="contact_part2_third_s2">
                <p>One of our consultants will get back to you ASAP.</p>
              </div> */}
            </div>
          </form>
        </div>
        {/* Captcha Pop-up */}
        {isCaptchaOpen && (
          <div className="captchaModal">
            <div className="captchaModalContent ar">
              {isLoading ? (
                <p>Loading...</p>
              ) : isSuccess ? (
                <>
                  <p>شكرًا لك، لقد تم إرسال بريدك الإلكتروني، وسيتواصل معك أحد أعضاء فريقنا قريبًا.</p>
                  <button onClick={() => setIsCaptchaOpen(false)}>Close</button>
                </>
              ) : (
                <>
                  <h3>التحقق البشرى</h3>
                  <p>ما هو جمع {captcha}</p>
                  <input type="text" placeholder="الإجابة" value={userCaptchaInput} onChange={(e) => setUserCaptchaInput(e.target.value)} required />
                  <button onClick={verifyCaptchaAndSendEmail}>تأكيد وإرسال</button>
                  <button onClick={() => setIsCaptchaOpen(false)}>إلغاء</button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function ContactS2() {
  return (
    <>
      <div className="Contact_S2 ar">
        <div className="Contact_S2_P1 ar">
          <div className="Contact_S2_P1_L ar" dir="rtl">
            <h1>المقر الرئيسي في الدوحة.</h1>
            <h3>عناويننا</h3>
            <h5 className="Contact_S2_P1_L_H5 ar">
              <strong> المصنع:</strong> بركة العوامر، منطقة 91 شارع 3083، مبنى 30، الدوحه – قطر
            </h5>
            <h5 className="Contact_S2_P1_L_H5 ar">
              <strong> المكتب الرئيسي: </strong> السد، منطقة 39 شارع 343، عمارة 61، الدوحه – قطر (من 9 ص الى 3 م)
            </h5>
            <h3 className="Contact_S2_P1_L_H3">رقم الهاتف</h3>
            <div className="Contact_S2_P1_L_Phone ar">
              <div className="Contact_Numbers_Phone" dir="ltr">
                <h6>(الخط الأرضي)</h6>
                <h7>+974 44986197</h7>
              </div>
              <div className="Contact_Numbers_Phone" dir="ltr">
                <h6>(رقم الجوال)</h6>
                <h7>+974 33222436</h7>
              </div>
            </div>
          </div>
          <div className="Contact_S2_P1_R">
            <a href="https://maps.app.goo.gl/qXmZXuuahYyh5Vg78" target="blank">
              <img src={ContactS2Img} alt="ContactS2Img" className="ContactS2Img" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

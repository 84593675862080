import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Hospitality/Aprons/APR1.webp";
import Image2 from "../../../../Product/Hospitality/Aprons/APR2.webp";
import Image3 from "../../../../Product/Hospitality/Aprons/APR3.webp";
import Image4 from "../../../../Product/Hospitality/Aprons/APR4.webp";
import Image5 from "../../../../Product/Hospitality/Aprons/APR5.webp";

const Aprons = () => {
  const images = [Image1, Image2, Image3, Image4, Image5];

  return (
    <ProductGallery
      images={images}
      category={{ english: "hospitality", enName: "Hospitality", arabic: "الضيافة", arName: "الضيافة" }}
      product={{ english: "Aprons", arabic: "مريولات طهاة" }}
    />
  );
};

export default Aprons;

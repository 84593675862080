import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Corporate/Dresses/DR1.webp";
import Image2 from "../../../../Product/Corporate/Dresses/DR2.webp";
import Image3 from "../../../../Product/Corporate/Dresses/DR3.webp";
import Image4 from "../../../../Product/Corporate/Dresses/DR4.webp";
import Image5 from "../../../../Product/Corporate/Dresses/DR5.webp";
import Image6 from "../../../../Product/Corporate/Dresses/DR6.webp";
import Image7 from "../../../../Product/Corporate/Dresses/DR7.webp";
import Image8 from "../../../../Product/Corporate/Dresses/DR8.webp";

const DressesCor = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

  return (
    <ProductGallery
      images={images}
      category={{ english: "corporate", enName: "Corporate", arabic: "الشركات", arName: "الشركات" }}
      product={{ english: "Dresses", arabic: "فساتين" }}
    />
  );
};
export default DressesCor;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Visibility_Shirt/VS1.webp";
import Image2 from "../../../../Product/Industrial/Visibility_Shirt/VS2.webp";
import Image3 from "../../../../Product/Industrial/Visibility_Shirt/VS3.webp";
import Image4 from "../../../../Product/Industrial/Visibility_Shirt/VS4.webp";
import Image5 from "../../../../Product/Industrial/Visibility_Shirt/VS5.webp";

const VisibilityShirt = () => {
  const images = [Image1, Image2, Image3, Image4, Image5];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Visibility Shirt", arabic: "قمصان مع عواكس" }}
    />
  );
};
export default VisibilityShirt;

import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./Header.css";
import Logo from "../../Assets/Svg/Logo.svg";
import SocialMediaIcons from "../StickySocialMedia/SocialMediaIcons";
import ButtonContactAr from "../ButtonContact/ButtonContactAr";

export default function ArabicHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageSwitch = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.includes("/ar");

    if (isArabic) {
      const basePath = currentPath.slice(0, -3);

      const newPath = basePath === "" ? "/" : basePath;

      navigate(newPath, { replace: true });
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div className="Header_Main_C">
        <div className="Header_Main_Container">
          <div className="Header_Container">
            <SocialMediaIcons />
            <div className="Language">
              <div>
                <ButtonContactAr />
              </div>
              <div className="Header_Languages" onClick={() => setShowDropdown(!showDropdown)}>
                <div className="Header_Languages_Main">
                  <h4 className="Language_Dropdown_Text" onClick={() => handleLanguageSwitch("en")}>
                    EN
                  </h4>
                  {/* <KeyboardArrowDownIcon style={{ color: "#b84031" }} /> */}
                </div>
                {/* {showDropdown && (
                  <div className="Language_Dropdown">
                    <button
                      className="Language_Dropdown_Text"
                      onClick={() => handleLanguageSwitch("en")}
                    >
                      الإنجليزية
                    </button>
                  </div>
                )} */}
              </div>
            </div>
            <div className="Menu">
              <Link to="/media/ar" className={`Menu_Text_Ar ${isActive("/media/ar") ? "active" : ""}`}>
                وسائط
              </Link>
              <Link to="/Categories/ar" className={`Menu_Text_Ar ${isActive("/Categories/ar") ? "active" : ""}`}>
                الفئات
              </Link>
              <Link to="/about-us/ar" className={`Menu_Text_Ar ${isActive("/about-us/ar") ? "active" : ""}`}>
                من نحن
              </Link>
              <Link to="/ar" className={`Menu_Text_Ar ${isActive("/ar") ? "active" : ""}`}>
                الصفحة الرئيسية
              </Link>
            </div>
            <div className="Logo">
              <Link to="/ar">
                <img src={Logo} alt="Logo" className="Header_Logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

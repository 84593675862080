import React from "react";
import "./ContactSection.css";
import ButtonContact from "../ButtonContact/ButtonContact";
import ButtonContactAr from "../ButtonContact/ButtonContactAr";
import { useLocation } from "react-router-dom";

export default function ContactSection() {
  const Location = useLocation();
  const isArabic = Location.pathname.endsWith("/ar");
  return (
    <>
      {isArabic ? (
        <div className="ContactSection ar">
          <h3>هل لديك أسئلة أو رؤية فريدة للزي الرسمي؟</h3>
          <h1 dir="rtl">اتصل بنا لاكتشاف منتجاتنا!</h1>
          <a href="/contact-us">
            <ButtonContactAr />
          </a>
        </div>
      ) : (
        <div className="ContactSection">
          <h3>Have questions or a unique uniform vision?</h3>
          <h1>Email us today to discuss how we can bring it to life!</h1>
          <a href="/contact-us">
            <ButtonContact />
          </a>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./Assets/Hooks/ScrollUp/ScrollUp";
import EnglishHeader from "./Components/Header/EnglishHeader";
import ArabicHeader from "./Components/Header/ArabicHeader";
import EnglishHeaderMobile from "./Components/Header/EnglishHeaderMobile";
import ArabicHeaderMobile from "./Components/Header/ArabicHeaderMobile";
import EnglishFooter from "./Components/Footer/EnglishFooter";
import ArabicFooter from "./Components/Footer/ArabicFooter";
import EnglishFooterMobile from "./Components/Footer/EnglishFooterMobile";
import ArabicFooterMobile from "./Components/Footer/ArabicFooterMobile";
import Home from "./Pages/Home/Home";
import HomeAr from "./Pages/Home/HomeAr";
import About from "./Pages/About/About";
import AboutAr from "./Pages/About/AboutAr";
import Gallery from "./Pages/Gallery/Gallery";
import GalleryAr from "./Pages/Gallery/GalleryAr";
import ContactUs from "./Pages/Contact/Contact";
import ContactUsAr from "./Pages/Contact/ContactAr";
import MediaContent from "./Pages/Media/Media";
import MediaContentAr from "./Pages/Media/MediaAr";
import VisibilityJacket from "./Pages/Gallery_Product/Industrial/Visibility_Jacket/Visibility_Jacket";
import Waistcoat from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/waistcoat/waistcoat";
import VestsSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Vests/VestsSchool";
import TrousersShorts from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Trousers-Shorts/TrousersShorts";
import SportsWear from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Sports-Wear/Sports-Wear";
import SkirtsSkorts from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Skirts-Skorts/Skirts-Skorts";
import ShirtsSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Shirts/shirts";
import PolosTShirts from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Polos_T-Shirts/Polos_T-Shirts";
import PinaFores from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/PinaFores/PinaFores";
import JumperSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Jumper/Jumper";
import GraduationGown from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Graduation-Gown/Graduation-Gown";
import Cardigans from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Cardigans/Cardigans";
import BlazersSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Blazers/Blazers";
import Coverall from "./Pages/Gallery_Product/Industrial/Coverall/Coverall";
import FireRetardantCoverall from "./Pages/Gallery_Product/Industrial/Fire_Retardant_coverall/Fire_Retardant_coverall";
import IndustrialVest from "./Pages/Gallery_Product/Industrial/Vest/Vest";
import VisibilityCoverall from "./Pages/Gallery_Product/Industrial/Visibility_Coverall/Visibility_Coverall";
import VisibilityHiVisBib from "./Pages/Gallery_Product/Industrial/Visibility_Hi_Vis_Bib/Visibility_Hi_Vis_Bib";
import VisibilityShirt from "./Pages/Gallery_Product/Industrial/Visibility_Shirt/Visibility_Shirt";
import VisibilitySuit from "./Pages/Gallery_Product/Industrial/Visibility_Suit/Visibility_Suit";
import Aprons from "./Pages/Gallery_Product/Hospitality/Aprons/Aprons";
import ChefCoat from "./Pages/Gallery_Product/Hospitality/Chef_Coat/Chef_Coat";
import ChefHat from "./Pages/Gallery_Product/Hospitality/Chef_Hat/Chef_Hat";
import ChefSuit from "./Pages/Gallery_Product/Hospitality/Chef_Suit/Chef_Suit";
import HoodySweatShirt from "./Pages/Gallery_Product/Casual/Hoody_Sweatshirt/Hoody_Sweatshirt";
import JacketsVestCas from "./Pages/Gallery_Product/Casual/Jackets_Vest_Cas/Jackets_Vest_Cas";
import PoloCasual from "./Pages/Gallery_Product/Casual/Polo_Cas/Polo_Cas";
import CapCasual from "./Pages/Gallery_Product/Casual/Cap_Cas/Cap_Cas";
import TopsBottomsSport from "./Pages/Gallery_Product/Sportswear/Tops&Bottoms-Sport/Tops&Bottoms-Sport";
import OutfitSport from "./Pages/Gallery_Product/Sportswear/Outfit-Sport/Outfit-Sport";
import SuitsVestsCor from "./Pages/Gallery_Product/Corporate/Suits&Vests-Cor/Suits&Vests-Cor";
import SecurityCor from "./Pages/Gallery_Product/Corporate/Security-Cor/Security-Cor";
import BlouseFormalCor from "./Pages/Gallery_Product/Corporate/Blouse&Formal-Shirts-Cor/Blouse&Formal-Shirts-Cor";
import DressesCor from "./Pages/Gallery_Product/Corporate/Dresses/Dresses";
import SkirtsTrousersCor from "./Pages/Gallery_Product/Corporate/Skirts&Trousers/Skirts&Trousers";

const routes = [
  { path: "/", enComponent: <Home />, arComponent: <HomeAr />, enTitle: "Home - Uniform Arena", arTitle: "الرئيسية - يونيفورم ارينا" },
  { path: "/about-us", enComponent: <About />, arComponent: <AboutAr />, enTitle: "About Us - Uniform Arena", arTitle: "من نحن - يونيفورم ارينا" },
  { path: "/Categories", enComponent: <Gallery />, arComponent: <GalleryAr />, enTitle: "Categories - Uniform Arena", arTitle: "الفئات - يونيفورم ارينا" },
  {
    path: "/contact-us",
    enComponent: <ContactUs />,
    arComponent: <ContactUsAr />,
    enTitle: "Contact Us - Uniform Arena",
    arTitle: "تواصل معنا - يونيفورم ارينا",
  },
  { path: "/media", enComponent: <MediaContent />, arComponent: <MediaContentAr />, enTitle: "Media - Uniform Arena", arTitle: "وسائط - يونيفورم ارينا" },
  // Industrial Uniforms
  {
    path: "/Coverall",
    enComponent: <Coverall />,
    arComponent: <Coverall />,
    enTitle: "Coverall - Uniform Arena",
    arTitle: "بدلات عمل كاملة - يونيفورم ارينا",
  },
  {
    path: "/FireRetardantCoverall",
    enComponent: <FireRetardantCoverall />,
    arComponent: <FireRetardantCoverall />,
    enTitle: "Fire Retardant Coverall - Uniform Arena",
    arTitle: "بدلات مقاومة للحريق - يونيفورم ارينا",
  },
  {
    path: "/IndustrialVest",
    enComponent: <IndustrialVest />,
    arComponent: <IndustrialVest />,
    enTitle: "Industrial Vest - Uniform Arena",
    arTitle: "سترات صناعية - يونيفورم ارينا",
  },
  {
    path: "/VisibilityCoverall",
    enComponent: <VisibilityCoverall />,
    arComponent: <VisibilityCoverall />,
    enTitle: "Visibility Coverall - Uniform Arena",
    arTitle: "بدلات عمل كاملة عاكسة - يونيفورم ارينا",
  },
  {
    path: "/VisibilityHiVisBib",
    enComponent: <VisibilityHiVisBib />,
    arComponent: <VisibilityHiVisBib />,
    enTitle: "Visibility Hi-Vis Bib - Uniform Arena",
    arTitle: "مريولات عاكسة - يونيفورم ارينا",
  },
  {
    path: "/VisibilityJacket",
    enComponent: <VisibilityJacket />,
    arComponent: <VisibilityJacket />,
    enTitle: "Visibility Jacket - Uniform Arena",
    arTitle: "جاكيتات عاكسة - يونيفورم ارينا",
  },
  {
    path: "/VisibilityShirt",
    enComponent: <VisibilityShirt />,
    arComponent: <VisibilityShirt />,
    enTitle: "Visibility Shirt - Uniform Arena",
    arTitle: "قمصان مع عواكس - يونيفورم ارينا",
  },
  {
    path: "/VisibilitySuit",
    enComponent: <VisibilitySuit />,
    arComponent: <VisibilitySuit />,
    enTitle: "Visibility Suit - Uniform Arena",
    arTitle: "بدلة عمل مع عواكس - يونيفورم ارينا",
  },
  // Educational Uniforms
  { path: "/Waistcoat", enComponent: <Waistcoat />, arComponent: <Waistcoat />, enTitle: "Waistcoat - Uniform Arena", arTitle: "صديري - يونيفورم ارينا" },
  {
    path: "/Vests-Schools",
    enComponent: <VestsSchool />,
    arComponent: <VestsSchool />,
    enTitle: "Vests - Uniform Arena",
    arTitle: "سترات - يونيفورم ارينا",
  },
  {
    path: "/Trousers-Shorts",
    enComponent: <TrousersShorts />,
    arComponent: <TrousersShorts />,
    enTitle: "Trousers & Shorts - Uniform Arena",
    arTitle: "بناطيل وسراويل قصيرة - يونيفورم ارينا",
  },
  {
    path: "/Sports-Wear",
    enComponent: <SportsWear />,
    arComponent: <SportsWear />,
    enTitle: "Sports Wear - Uniform Arena",
    arTitle: "ملابس رياضية - يونيفورم ارينا",
  },
  {
    path: "/Skirts-Skorts",
    enComponent: <SkirtsSkorts />,
    arComponent: <SkirtsSkorts />,
    enTitle: "Skirts & Skorts - Uniform Arena",
    arTitle: "التنانير والتناينر القصيرة - يونيفورم ارينا",
  },
  {
    path: "/Shirts-School",
    enComponent: <ShirtsSchool />,
    arComponent: <ShirtsSchool />,
    enTitle: "Shirts - Uniform Arena",
    arTitle: "قمصان - يونيفورم ارينا",
  },
  {
    path: "/Polos-TShirts",
    enComponent: <PolosTShirts />,
    arComponent: <PolosTShirts />,
    enTitle: "Polos & T-Shirts - Uniform Arena",
    arTitle: "تيشرتات بولو - يونيفورم ارينا",
  },
  { path: "/PinaFores", enComponent: <PinaFores />, arComponent: <PinaFores />, enTitle: "Pinafores - Uniform Arena", arTitle: "مريولات - يونيفورم ارينا" },
  {
    path: "/Jumper-School",
    enComponent: <JumperSchool />,
    arComponent: <JumperSchool />,
    enTitle: "Jumper - Uniform Arena",
    arTitle: "تيشرتات صوف - يونيفورم ارينا",
  },
  {
    path: "/Graduation-Gown",
    enComponent: <GraduationGown />,
    arComponent: <GraduationGown />,
    enTitle: "Graduation Gown - Uniform Arena",
    arTitle: "ثوب التخرج - يونيفورم ارينا",
  },
  {
    path: "/Cardigans-School",
    enComponent: <Cardigans />,
    arComponent: <Cardigans />,
    enTitle: "Cardigans - Uniform Arena",
    arTitle: "سترات صوفية - يونيفورم ارينا",
  },
  {
    path: "/Blazers-School",
    enComponent: <BlazersSchool />,
    arComponent: <BlazersSchool />,
    enTitle: "Blazers - Uniform Arena",
    arTitle: "بلايزرات - يونيفورم ارينا",
  },
  // Hospitality Uniforms
  { path: "/Aprons", enComponent: <Aprons />, arComponent: <Aprons />, enTitle: "Aprons - Uniform Arena", arTitle: "مريولات طهاة - يونيفورم ارينا" },
  { path: "/ChefCoat", enComponent: <ChefCoat />, arComponent: <ChefCoat />, enTitle: "Chef Coat - Uniform Arena", arTitle: "معاطف الطهاة - يونيفورم ارينا" },
  { path: "/ChefHat", enComponent: <ChefHat />, arComponent: <ChefHat />, enTitle: "Chef Hat - Uniform Arena", arTitle: "قبعات الطهاة - يونيفورم ارينا" },
  { path: "/ChefSuit", enComponent: <ChefSuit />, arComponent: <ChefSuit />, enTitle: "Chef Suit - Uniform Arena", arTitle: "بدلات الطهاة - يونيفورم ارينا" },
  // Casual Uniforms
  {
    path: "/HoodySweatShirt",
    enComponent: <HoodySweatShirt />,
    arComponent: <HoodySweatShirt />,
    enTitle: "Hoody Sweatshirt - Uniform Arena",
    arTitle: "هودي - يونيفورم ارينا",
  },
  {
    path: "/JacketsVestCas",
    enComponent: <JacketsVestCas />,
    arComponent: <JacketsVestCas />,
    enTitle: "Jackets & Vests - Uniform Arena",
    arTitle: "جاكيتات وسترات - يونيفورم ارينا",
  },
  {
    path: "/PoloCasual",
    enComponent: <PoloCasual />,
    arComponent: <PoloCasual />,
    enTitle: "Polo - Uniform Arena",
    arTitle: "بولو - يونيفورم ارينا",
  },
  {
    path: "/CapCasual",
    enComponent: <CapCasual />,
    arComponent: <CapCasual />,
    enTitle: "Cap - Uniform Arena",
    arTitle: "قبعات - يونيفورم ارينا",
  },
  // Sportswear Uniforms
  {
    path: "/TopsBottomsSport",
    enComponent: <TopsBottomsSport />,
    arComponent: <TopsBottomsSport />,
    enTitle: "Sports Tops & Bottoms - Uniform Arena",
    arTitle: "ملابس علوية وسفلية - يونيفورم ارينا",
  },
  {
    path: "/OutfitSport",
    enComponent: <OutfitSport />,
    arComponent: <OutfitSport />,
    enTitle: "Outfit - Uniform Arena",
    arTitle: "ملابس رياضية كاملة - يونيفورم ارينا",
  },
  // Corporate Uniforms
  {
    path: "/SuitsVestsCor",
    enComponent: <SuitsVestsCor />,
    arComponent: <SuitsVestsCor />,
    enTitle: "Suits & Vests - Uniform Arena",
    arTitle: "بدلات وسترات - يونيفورم ارينا",
  },
  {
    path: "/SecurityCor",
    enComponent: <SecurityCor />,
    arComponent: <SecurityCor />,
    enTitle: "Security - Uniform Arena",
    arTitle: "ملابس أمنية - يونيفورم ارينا",
  },
  {
    path: "/BlouseFormalCor",
    enComponent: <BlouseFormalCor />,
    arComponent: <BlouseFormalCor />,
    enTitle: "Blouse & Formal Shirts - Uniform Arena",
    arTitle: "بلوزات وقمصان رسمية - يونيفورم ارينا",
  },
  {
    path: "/Dresses",
    enComponent: <DressesCor />,
    arComponent: <DressesCor />,
    enTitle: "Dresses - Uniform Arena",
    arTitle: "فساتين - يونيفورم ارينا",
  },
  {
    path: "/Skirts-Trousers",
    enComponent: <SkirtsTrousersCor />,
    arComponent: <SkirtsTrousersCor />,
    enTitle: "Skirts & Trousers - Uniform Arena",
    arTitle: "تنانير وبناطيل - يونيفورم ارينا",
  },
];

export default function LanguageRoutes() {
  const location = useLocation();
  const isArabic = location.pathname.endsWith("/ar");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Normalize the path by removing "/ar" suffix for lookup, with special case for root
    const basePath = isArabic ? (location.pathname === "/ar" ? "/" : location.pathname.replace(/\/ar$/, "")) : location.pathname;
    const route = routes.find((r) => r.path === basePath);
    document.title = route ? (isArabic ? route.arTitle : route.enTitle) : isArabic ? "يونيفورم ارينا" : "Uniform Arena";
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HeaderComponent = isSmallScreen ? (isArabic ? ArabicHeaderMobile : EnglishHeaderMobile) : isArabic ? ArabicHeader : EnglishHeader;
  const FooterComponent = isSmallScreen ? (isArabic ? ArabicFooterMobile : EnglishFooterMobile) : isArabic ? ArabicFooter : EnglishFooter;

  return (
    <>
      <HeaderComponent />
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, enComponent, arComponent }, index) => (
          <Route key={index} path={path} element={isArabic ? arComponent : enComponent} />
        ))}
        {routes.map(({ path, arComponent }, index) => (
          <Route key={index} path={`${path}/ar`} element={arComponent} />
        ))}
      </Routes>
      <FooterComponent />
    </>
  );
}

import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import HomeS1Img from "../../Assets/Images/HomeS1.webp";
import HomeS2ImgLoop from "../../Components/Home/HomeS2ImgesLoop/HomeS2ImagesLoop";
import HomeS3P2C1L1 from "../../Assets/Svg/Home_S3_P2_C1_L1.svg";
import HomeS3P2C1L2 from "../../Assets/Svg/Home_S3_P2_C1_L2.svg";
import HomeS3P2C1L3 from "../../Assets/Svg/Home_S3_P2_C1_L3.svg";
import HomeS3P2C1L4 from "../../Assets/Svg/Home_S3_P2_C1_L4.svg";
import HomeS3P2C from "../../Components/Home/HomeS3P2C/HomeS3P2C";
import HomeS4P2Img1 from "../../Assets/Images/HomeS4_P2_Img1.webp";
import HomeS4P2Img2 from "../../Assets/Images/HomeS4_P2_Img2.webp";
import HomeS4P2Img3 from "../../Assets/Images/HomeS4_P2_Img3.webp";
import HomeS5P2 from "../../Components/Home/HomeS5P2/HomeS5P2";
import HomeS6ImgRN from "../../Assets/Images/HomeS6ImgL.webp";
import HomeS6ImgL from "../../Assets/Svg/HomeS6P1B-B.svg";
import HomeS7P1ImgN from "../../Assets/Images/HomeS7P1Img.webp";
import HomeS7P2ImgB from "../../Assets/Svg/HomeS7P2B-B.svg";
import ContactSection from "../../Components/ContactSection/ContactSection";

export default function HomeArContent() {
  return (
    <>
      <div className="Home_Container">
        <HomeS1 />
        <HomeS2 />
        <HomeS3 />
        <HomeS4 />
        <HomeS5 />
        <HomeS6 />
        <HomeS7 />
        {/* <HomeS8 /> */}
        <HomeS9 />
      </div>
    </>
  );
}
//
function HomeS1() {
  return (
    <>
      <div className="HomeS1" dir="rtl">
        <div className="HomeS1_Container">
          <div className="HomeS1_P1">
            <h3 className="HomeS1_P1_H3 ar">تصنيع الأزياء الرسمية الفاخرة.</h3>
            <h1 className="HomeS1_P1_H1 ar">في كل إطلالة للأزياء الرسمية، ستعطي فريقك الشعور بالنجاح والثقة.</h1>
            <img src={HomeS1Img} alt="" className="HomeS1ImgMob" />
            <p className="HomeS1_P1_P ar">عزز هويتك التجارية بزي رسمي احترافي.. يترك انطباعا لا ينسى.</p>
          </div>
        </div>
        <div className="HomeS1_P2">
          <img src={HomeS1Img} alt="HomeS1Img" className="HomeS1Img" />
        </div>
      </div>
    </>
  );
}
//
function HomeS2() {
  return (
    <>
      <div className="HomeS2C">
        <div className="HomeS2">
          <h3 className="HomeS2_P1_H3 ar" dir="rtl">
            أكثر من <span>+20</span> قصة نجاح
          </h3>
          <h1 className="HomeS2_P1_H1 ar" dir="rtl">
            عملاؤنا
          </h1>
          <HomeS2ImgLoop />
        </div>
      </div>
    </>
  );
}

function HomeS3() {
  return (
    <>
      <div className="HomeS3">
        <div className="HomeS3_P1" dir="rtl">
          <h1 className="HomeS3_P1_H1 ar">قيمنا</h1>
          <p className="HomeS3_P1_P ar">
            في يونيفورم ارينا، لدينا الشغف بتصنيع <br /> زي موحد مصمم لك خصيصا.
          </p>
        </div>
        <div className="HomeS3_P2">
          {/*  */}
          <div className="HomeS3_P2_C">
            {/* Box 1 */}
            <HomeS3P2C
              Image={HomeS3P2C1L1}
              H1Text="العميل أولويتنا"
              PText="نحرص على التعاون مع عملائنا لفهم احتياجاتهم بدقة وتقديم أزياء موحدة مصممة خصيصًا لهم تعزز صورتهم المهنية وتوفر الراحة والرضا لموظفيهم."
            />
            {/* Box 2 */}
            <HomeS3P2C
              Image={HomeS3P2C1L2}
              H1Text="الشغف"
              PText="لأن نجاحك من ضمن أولوياتنا.. يدفعنا شغفنا الدائم الى تصميم أزياء رسمية راقية، مع التزامنا بتقديم خدمة عملاء استثنائية."
            />
          </div>
          {/*  */}
          <div className="HomeS3_P2_C">
            {/* Box 3 */}
            <HomeS3P2C
              Image={HomeS3P2C1L3}
              H1Text="التميّز"
              PText="نصنع ملابس راقية تتخطى صعوبات التصنيع، حيث نستثمر دائما في التقنيات الحديثة، ونسعى باستمرار لتجاوز الحدود لتقديم نتائج استثنائية."
            />
            {/* Box 4 */}
            <HomeS3P2C
              Image={HomeS3P2C1L4}
              H1Text="الابتكار"
              PText="فريقنا يسعى دائمًا دمج أحدث التقنيات في تصنيع زيّ رسمي يجمع بين يجمع بين الحداثة، الراحة، والأناقة."
            />
          </div>
        </div>
      </div>
    </>
  );
}
//
function HomeS4() {
  return (
    <>
      <div className="HomeS4">
        <div className="HomeS4_P1">
          <h1 className="HomeS4_P1_H1 ar">تصفح تشكيلاتنا الفريدة</h1>
          <div className="HomeS4_P2_Mob">
            <img src={HomeS4P2Img1} alt="HomeS4P2Img1" className="HomeS4P2Img" />
            <img src={HomeS4P2Img2} alt="HomeS4P2Img2" className="HomeS4P2Img" />
            <img src={HomeS4P2Img3} alt="HomeS4P2Img3" className="HomeS4P2Img" />
          </div>
          <p className="HomeS4_P1_P ar" dir="rtl">
            اكتشف تشكيلتنا المتنوعة للأزياء المصممة خصيصاً لاحتياجات المدارس والمؤسسات، حيث نقدم مجموعة مختلفة... "من التصميم التقليدي إلى العصري" لتناسب مختلف
            الأذواق والمتطلبات.
          </p>
          <Link to="/Categories/ar" className="HomeS4_P1_A">
            <button className="HomeS4_P1_B ar">تصفح مجموعتنا المميزة</button>
          </Link>
        </div>
        <div className="HomeS4_P2">
          <img src={HomeS4P2Img1} alt="HomeS4P2Img1" className="HomeS4P2Img" />
          <img src={HomeS4P2Img2} alt="HomeS4P2Img2" className="HomeS4P2Img" />
          <img src={HomeS4P2Img3} alt="HomeS4P2Img3" className="HomeS4P2Img" />
        </div>
      </div>
    </>
  );
}
function HomeS5() {
  return (
    <>
      <div className="HomeS5">
        <div className="HomeS5_P1">
          <h1 className="HomeS5_P1_H1 ar" dir="rtl">
            نوفر خيارات متعددة من الزيّ الرسمي، التي تتناسب مع مختلف المجالات
          </h1>
        </div>
        <div>
          <HomeS5P2 />
        </div>
      </div>
    </>
  );
}
function HomeS6() {
  return (
    <>
      <div className="HomeS6">
        <div className="HomeS6_P1 ar" dir="rtl">
          <div className="HomeS6P1ImgC">
            <img src={HomeS6ImgL} alt="" className="HomeS6P1ImgB" />
          </div>
          <h5>في يونيفورم ارينا</h5>
          <h1>إبداع في كل تفصيلة</h1>
          <div className="HomeS6_P2 HomeS6_P2_Mob">
            <img src={HomeS6ImgRN} alt="HomeS6ImgR" className="HomeS6ImgR" />
          </div>
          <p className="HomeS6_P1_P ar">
            يدرك فريقنا من المصممين ذوي الخبرة أهمية تخصيص التصميمات، حيث نعمل عن كثب معك لتجسيد رؤيتك وتحويلها إلى زي رسمي مميز سواء كنت تحتاج إلى{" "}
            <span>ألوان</span> أو <span>شعارات</span> أو <span>أقمشة خاصة</span>، تهمنا كل تفاصيل منتجك ونصنعها بعناية لضمان أن يكون الزي فريدًا يعكس هويتك
            بالشكل الأمثل.
          </p>
        </div>
        <div className="HomeS6_P2 HomeS6_P2_Web">
          <img src={HomeS6ImgRN} alt="HomeS6ImgR" className="HomeS6ImgR" />
        </div>
      </div>
    </>
  );
}
function HomeS7() {
  return (
    <>
      <div className="HomeS7">
        <div className="HomeS7P1">
          <div className="HomeS7P1Img HomeS7P1Img_Web">
            <img src={HomeS7P1ImgN} alt="HomeS7P1Img" className="HomeS7P1Image" />
          </div>
        </div>
        <div className="HomeS7P2 ar" dir="rtl">
          <img src={HomeS7P2ImgB} alt="" className="HomeS7P2ImgB" />
          <h3>في يونيفورم ارينا</h3>
          <h1>جودة يمكنك أن تثق به</h1>
          <div className="HomeS7P1Img HomeS7P1Img_Mob">
            <img src={HomeS7P1ImgN} alt="HomeS7P1Img" className="HomeS7P1Image" />
          </div>
          <p>
            في يونيفورم أرينا الجودة هي أولويتنا، نستخدم أفضل الخامات ونتبع إجراءات مراقبة الجودة لضمان أن كل قطعة ملابس تلبي أعلى معاييرنا من المتانة إلى
            الراحة، تم تصميم ملابسنا الرسمية لتتماشى مع متطلبات الاستخدام اليومي بكل كفاءة.
          </p>
        </div>
      </div>
    </>
  );
}

function HomeS9() {
  return (
    <>
      <ContactSection />
    </>
  );
}

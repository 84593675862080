import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Casual/Polo_Cas/p1.webp";
import Image2 from "../../../../Product/Casual/Polo_Cas/p2.webp";
import Image3 from "../../../../Product/Casual/Polo_Cas/p3.webp";
import Image4 from "../../../../Product/Casual/Polo_Cas/p4.webp";
import Image5 from "../../../../Product/Casual/Polo_Cas/p5.webp";
import Image6 from "../../../../Product/Casual/Polo_Cas/p6.webp";
import Image7 from "../../../../Product/Casual/Polo_Cas/p7.webp";

const PoloCasual = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  return (
    <ProductGallery
      images={images}
      category={{ english: "casual", enName: "Casual", arabic: "كاجوال", arName: "كاجوال" }}
      product={{ english: "Polo", arabic: "بولو" }}
    />
  );
};

export default PoloCasual;

import React from "react";
import "./Gallery.css";
import AboutS1P1BIcon from "../../Assets/Svg/AboutS1P1BIcon.svg";
import LineSpace from "../../Assets/Svg/Line_Space.svg";
import GalleryS3Com from "../../Components/GalleryS3/GalleryS3";
import CompanyCatalogue from "../../Assets/PDF/Company_Catalogue.pdf";
import ContactSection from "../../Components/ContactSection/ContactSection";

export default function GalleryContent() {
  return (
    <>
      <GalleryS1 />
      {/* <GalleryS2 /> */}
      <GalleryS3 />
      <GalleryS4 />
    </>
  );
}

function GalleryS1() {
  return (
    <div className="GalleryS1">
      <div className="GalleryS1_P1 Cont">
        <h1>Categories</h1>
        <p>
          Welcome to the Uniform Arena Uniform Inspiration Categories! Here, you'll discover a collection of our finest uniform creations, showcasing the
          quality, style, and versatility of our designs.
        </p>
      </div>
      <div className="GalleryS1_P1 GalleryS1_P1_Mob">
        <h3>Get a digital brochure of our uniform offerings.</h3>
        <a href={CompanyCatalogue} download="Company_Catalogue.pdf" className="HomeS4_P1_A">
          <button className="GalleryS1_B">
            Download Our Catalogue
            <img src={AboutS1P1BIcon} alt="" />
          </button>
        </a>
      </div>
    </div>
  );
}

// function GalleryS2() {
//   return (
//     <div className="GalleryS2">
//       <div className="GalleryS2_Space">
//         <img src={LineSpace} alt="" />
//       </div>
//     </div>
//   );
// }

function GalleryS3() {
  return (
    <>
      <div className="GalleryS3Com">
        <div className="GalleryS3T">choose your field</div>
        <GalleryS3Com />
      </div>
    </>
  );
}

function GalleryS4() {
  return <ContactSection />;
}

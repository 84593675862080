import React from "react";
import { useLocation } from "react-router-dom";
import "./ImageSlider.css";
import { ReactComponent as ArrowLeftGallery } from "../../Assets/Svg/Arrow_left_Gallery.svg";
import { ReactComponent as ArrowRightGallery } from "../../Assets/Svg/Arrow_left_Gallery.svg";

const ImageSlider = ({ images, currentIndex, setCurrentIndex }) => {
  const Location = useLocation();
  const isArabic = Location.pathname.endsWith("/ar");
  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <>
      {isArabic ? (
        <div className="image-slider">
          <button onClick={goToNext} className="ButtoN_Icon">
            <ArrowRightGallery className="ButtoN_Icon2" />
          </button>
          <img src={images[currentIndex]} alt="Current" />
          <button onClick={goToPrevious} className="ButtoN_Icon">
            <ArrowLeftGallery className="ButtoN_Icon" />
          </button>
        </div>
      ) : (
        <div className="image-slider">
          <button onClick={goToPrevious} className="ButtoN_Icon">
            <ArrowLeftGallery className="ButtoN_Icon" />
          </button>
          <img src={images[currentIndex]} alt="Current" />
          <button onClick={goToNext} className="ButtoN_Icon">
            <ArrowRightGallery className="ButtoN_Icon2" />
          </button>
        </div>
      )}
    </>
  );
};

export default ImageSlider;

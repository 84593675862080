import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Visibility_Coverall/VC1.webp";
import Image2 from "../../../../Product/Industrial/Visibility_Coverall/VC2.webp";
import Image3 from "../../../../Product/Industrial/Visibility_Coverall/VC3.webp";
import Image4 from "../../../../Product/Industrial/Visibility_Coverall/VC4.webp";

const VisibilityCoverall = () => {
  const images = [Image1, Image2, Image3, Image4];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Visibility Coverall", arabic: "بدلات عمل كاملة عاكسة" }}
    />
  );
};

export default VisibilityCoverall;

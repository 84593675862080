import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC1.webp";
import Image2 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC2.webp";
import Image3 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC3.webp";
import Image4 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC4.webp";

const FireRetardantCoverall = () => {
  const images = [Image1, Image2, Image3, Image4];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Fire Retardant Coverall", arabic: "بدلات مقاومة للحريق" }}
    />
  );
};

export default FireRetardantCoverall;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Coverall/CVA1.webp";
import Image2 from "../../../../Product/Industrial/Coverall/CVA2.webp";
import Image3 from "../../../../Product/Industrial/Coverall/CVA3.webp";
import Image4 from "../../../../Product/Industrial/Coverall/CVA4.webp";
import Image5 from "../../../../Product/Industrial/Coverall/CVA5.webp";

const Coverall = () => {
  const images = [Image1, Image2, Image3, Image4, Image5];

  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Coverall", arabic: "بدلات عمل كاملة" }}
    />
  );
};

export default Coverall;

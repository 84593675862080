import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Cardigans/C1.webp";
import Image2 from "../../../../Product/School_Uniforms/Cardigans/C2.webp";
import Image3 from "../../../../Product/School_Uniforms/Cardigans/C3.webp";
import Image4 from "../../../../Product/School_Uniforms/Cardigans/C4.webp";

const Cardigans = () => {
  const images = [Image1, Image2, Image3, Image4];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Cardigans", arabic: "سترات صوفية" }}
    />
  );
};

export default Cardigans;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Skirts-Skorts/S1.webp";
import Image2 from "../../../../Product/School_Uniforms/Skirts-Skorts/S2.webp";
import Image3 from "../../../../Product/School_Uniforms/Skirts-Skorts/S3.webp";
import Image4 from "../../../../Product/School_Uniforms/Skirts-Skorts/S4.webp";
import Image5 from "../../../../Product/School_Uniforms/Skirts-Skorts/S5.webp";
import Image6 from "../../../../Product/School_Uniforms/Skirts-Skorts/S6.webp";
import Image7 from "../../../../Product/School_Uniforms/Skirts-Skorts/S7.webp";

const SkirtsSkorts = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];
  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Skirts & Skorts", arabic: "التنانير والتناينر القصيرة" }}
    />
  );
};

export default SkirtsSkorts;

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS1.webp";
import Image2 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS2.webp";
import Image3 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS3.webp";
import Image4 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS4.webp";
import Image5 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS5.webp";
import Image6 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS6.webp";
import Image7 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS7.webp";
import Image8 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS8.webp";
import Image9 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS9.webp";
import Image10 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS10.webp";
import Image11 from "../../../../Product/School_Uniforms/Trousers-Shorts/TS11.webp";

const TrousersShorts = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Trousers Shorts", arabic: "بناطيل وسراويل قصيرة" }}
    />
  );
};

export default TrousersShorts;

import React from "react";
import "./HomeS3P2C.css";
import { useLocation } from "react-router-dom";
export default function HomeS3P2C({ Image, H1Text, PText }) {
  const Location = useLocation();
  const isArabic = Location.pathname.endsWith("/ar");
  return (
    <>
      {isArabic ? (
        <div className="HomeS3_P2_C_L">
          <div className="HomeS3_P2_C_L_Main">
            <img src={Image} alt="HomeS3P2C1L1" className="HomeS3P2C1L1" />
            <h1 className="HomeS3_P2_C_L_H1 ar" dir="rtl">
              {H1Text}
            </h1>
          </div>
          <p className="HomeS3_P2_C_L_P ar" dir="rtl">
            {PText}
          </p>
        </div>
      ) : (
        <div className="HomeS3_P2_C_L">
          <div className="HomeS3_P2_C_L_Main">
            <img src={Image} alt="HomeS3P2C1L1" className="HomeS3P2C1L1" />
            <h1 className="HomeS3_P2_C_L_H1">{H1Text}</h1>
          </div>
          <p className="HomeS3_P2_C_L_P">{PText}</p>
        </div>
      )}
    </>
  );
}

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Industrial/Visibility_Suit/VSBS1.webp";
import Image2 from "../../../../Product/Industrial/Visibility_Suit/VSBS2.webp";
import Image3 from "../../../../Product/Industrial/Visibility_Suit/VSBS3.webp";
import Image4 from "../../../../Product/Industrial/Visibility_Suit/VSBS4.webp";

const VisibilitySuit = () => {
  const images = [Image1, Image2, Image3, Image4];
  return (
    <ProductGallery
      images={images}
      category={{ english: "industrial", enName: "Industrial", arabic: "الصناعة", arName: "الصناعة" }}
      product={{ english: "Visibility Suit", arabic: "بدلة عمل مع عواكس" }}
    />
  );
};

export default VisibilitySuit;

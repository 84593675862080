import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Casual/Cap_Cas/Cp1.webp";
import Image2 from "../../../../Product/Casual/Cap_Cas/Cp2.webp";
import Image3 from "../../../../Product/Casual/Cap_Cas/Cp3.webp";
import Image4 from "../../../../Product/Casual/Cap_Cas/Cp4.webp";
import Image5 from "../../../../Product/Casual/Cap_Cas/Cp5.webp";
import Image6 from "../../../../Product/Casual/Cap_Cas/Cp6.webp";

const CapCasual = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "casual", enName: "Casual", arabic: "كاجوال", arName: "كاجوال" }}
      product={{ english: "Cap", arabic: "قبعات" }}
    />
  );
};

export default CapCasual;

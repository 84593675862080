import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ArrowLeft from "../../Assets/Svg/arrow_forward_Gallery.svg";
import ImageSlider from "../../Components/Gallery_Images/ImageSlider";
import ThumbnailGallery from "../../Components/Gallery_Images/ThumbnailGallery";
import "./Gallery_Product.css";

const ProductGallery = ({ images, category, product }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const isArabic = location.pathname.endsWith("/ar");

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const handleCategoryClick = () => {
    const path = isArabic ? "/Categories/ar" : "/Categories";
    navigate(path, { state: { selectedCategory: category.english } });
  };

  return (
    <div className={`Gallery_Product_Container ${isArabic ? "ar" : ""}`} dir={isArabic ? "rtl" : undefined}>
      <div className={`Gallery_Product_S1 ${isArabic ? "ar" : ""}`}>
        <Link to={isArabic ? "/Categories/ar" : "/Categories"}>{isArabic ? "الفئات" : "Categories"}</Link>
        <img src={ArrowLeft} alt="" />
        <h3 onClick={handleCategoryClick} style={{ cursor: "pointer" }}>
          {isArabic ? category.arName : category.enName}
        </h3>
        <img src={ArrowLeft} alt="" />
        <h4>{isArabic ? product.arabic : product.english}</h4>
      </div>
      <div className="Gallery_Product_S2">
        <ImageSlider images={images} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      </div>
      <div className="Gallery_Product_S3">
        <ThumbnailGallery images={images} onThumbnailClick={handleThumbnailClick} />
      </div>
    </div>
  );
};

export default ProductGallery;

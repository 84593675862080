import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Hospitality/Chef_Hat/CH1.webp";
import Image2 from "../../../../Product/Hospitality/Chef_Hat/CH2.webp";
import Image3 from "../../../../Product/Hospitality/Chef_Hat/CH3.webp";
import Image4 from "../../../../Product/Hospitality/Chef_Hat/CH4.webp";
import Image5 from "../../../../Product/Hospitality/Chef_Hat/CH5.webp";
import Image6 from "../../../../Product/Hospitality/Chef_Hat/CH6.webp";

const ChefHat = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "hospitality", enName: "Hospitality", arabic: "الضيافة", arName: "الضيافة" }}
      product={{ english: "Chef Hat", arabic: "قبعات الطهاة" }}
    />
  );
};

export default ChefHat;

import React, { useState, useEffect, useRef } from "react";
import HomeS2Img1 from "../../../Assets/Images/HomeS2/HomeS2Img1.webp";
import HomeS2Img2 from "../../../Assets/Images/HomeS2/HomeS2Img2.webp";
import HomeS2Img3 from "../../../Assets/Images/HomeS2/HomeS2Img3.webp";
import HomeS2Img4 from "../../../Assets/Images/HomeS2/HomeS2Img4.webp";
import HomeS2Img5 from "../../../Assets/Images/HomeS2/HomeS2Img5.webp";
import HomeS2Img6 from "../../../Assets/Images/HomeS2/HomeS2Img6.webp";
import HomeS2Img7 from "../../../Assets/Images/HomeS2/HomeS2Img7.webp";
import HomeS2Img8 from "../../../Assets/Images/HomeS2/HomeS2Img8.webp";
import HomeS2Img9 from "../../../Assets/Images/HomeS2/HomeS2Img9.webp";
import HomeS2Img10 from "../../../Assets/Images/HomeS2/HomeS2Img10.webp";
import HomeS2Img11 from "../../../Assets/Images/HomeS2/HomeS2Img11.webp";
import HomeS2Img12 from "../../../Assets/Images/HomeS2/HomeS2Img12.webp";
import HomeS2Img13 from "../../../Assets/Images/HomeS2/HomeS2Img13.webp";

import "./HomeS2ImagesLoop.css";
export default function HomeS2ImgLoop() {
  const [offset, setOffset] = useState(0);
  const images = [
    HomeS2Img1,
    HomeS2Img2,
    HomeS2Img3,
    HomeS2Img4,
    HomeS2Img5,
    HomeS2Img6,
    HomeS2Img7,
    HomeS2Img8,
    HomeS2Img9,
    HomeS2Img10,
    HomeS2Img11,
    HomeS2Img12,
    HomeS2Img13,
  ];
  const imageRef = useRef(null);

  const updatePosition = () => {
    setOffset((prevOffset) => {
      const elementWidth = imageRef.current.scrollWidth;
      // This should be the width of a single set of images
      const resetOffset = elementWidth / 2; // Assuming images array is not tripled
      return prevOffset >= resetOffset ? 0 : prevOffset + 1; // Move right 1px per update
    });
  };

  useEffect(() => {
    const interval = setInterval(updatePosition, 10); // Adjust time to control speed
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="HomeS2_P2">
      <div ref={imageRef} style={{ transform: `translateX(-${offset}px)`, display: "flex" }}>
        {images.map((img, index) => (
          <img key={index} src={img} alt={`HomeS2Img${index + 1}`} className={`HomeS2Img HS2Img${index + 1}`} />
        ))}
        {/* we repeat it to not make a space */}
        {images.map((img, index) => (
          <img key={index + images.length} src={img} alt={`HomeS2Img${index + 1 + images.length}`} className={`HomeS2Img HS2Img${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
// import FooterFrame from "../../Assets/Svg/FooterFrame.svg";
import FooterLogo from "../../Assets/Svg/FooterLogo.svg";
import FacebookIcon from "../../Assets/Svg/FaceIconWhite.svg";
// import LinkedInIcon from "../../Assets/Svg/LinkedIconWhite.svg";
import InstagramIcon from "../../Assets/Svg/InstaIconWhite.svg";
import WhatsApp from "../../Assets/Svg/Whatsapp.svg";

export default function EnglishFooterMobile() {
  return (
    <div className="Footer_Container Footer_Container_Mob">
      {/* footer part one */}
      <div className="Footer_P1">
        <img src={FooterLogo} alt="FooterLogo" className="FooterLogo" />
        <h3 className="Footer_P1_H3">Ready to elevate your team's image? Contact Uniform Arena today!</h3>
      </div>
      {/* footer part two */}
      <div className="Footer_P2">
        <Link to="/">Home</Link>
        <Link to="/about-us">About us</Link>
        <Link to="/Categories">Categories</Link>
        <Link to="/media">Media</Link>
        <Link to="/contact-us">Contact</Link>
      </div>
      {/* footer part three */}
      <div className="Footer_P3 Footer_P3_Mob">
        <a href="https://www.facebook.com/uniformarena" target="_blank" rel="noopener noreferrer">
          <img src={FacebookIcon} alt="FacebookIcon" className="FacebookIconFooter" />
        </a>
        {/* <a href="https://www.linkedin.com/company/uniformarena" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedInIcon" />
        </a> */}
        <a href="https://www.instagram.com/uniformarena/" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt="InstagramIcon" />
        </a>
        <a href="https://wa.me/97433222436" target="_blank" rel="noopener noreferrer">
          <img src={WhatsApp} alt="InstagramIcon" />
        </a>
      </div>
      {/* footer part four */}
      <div>
        <h3 className="Footer_P4_H3 Footer_P4_H3_Mob">Copyright © 2025. Uniform Arena. All rights reserved.</h3>
      </div>
    </div>
  );
}

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs1.webp";
import Image2 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs2.webp";
import Image3 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs3.webp";
import Image4 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs4.webp";
import Image5 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs5.webp";
import Image6 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs6.webp";
import Image7 from "../../../../Product/Corporate/Suits&Vests-Cor/St&Vs7.webp";

import "../../Gallery_Product.css";

const SuitsVestsCor = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  return (
    <ProductGallery
      images={images}
      category={{ english: "corporate", enName: "Corporate", arabic: "الشركات", arName: "الشركات" }}
      product={{ english: "Suits & Vests", arabic: "بدلات وسترات" }}
    />
  );
};

export default SuitsVestsCor;

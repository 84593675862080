import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs1.webp";
import Image2 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs2.webp";
import Image3 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs3.webp";
import Image4 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs4.webp";
import Image5 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs5.webp";
import Image6 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs6.webp";
import Image7 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs7.webp";
import Image8 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs8.webp";

const BlouseFormalCor = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

  return (
    <ProductGallery
      images={images}
      category={{ english: "corporate", enName: "Corporate", arabic: "الشركات", arName: "الشركات" }}
      product={{ english: "Blouse & formal shirts", arabic: "بلوزات وقمصان رسمية" }}
    />
  );
};
export default BlouseFormalCor;

import React from "react";
import "./About.css";
import AboutS1P1BIcon from "../../Assets/Svg/AboutS1P1BIcon.svg";
import AboutS2P2L from "../../Assets/Images/AboutS2P2L.webp";
import AboutS2P2R from "../../Assets/Images/AboutS2P2R.webp";
import CompanyProfile from "../../Assets/PDF/Company_Profile.pdf";
import CompanyCatalogue from "../../Assets/PDF/Company_Catalogue.pdf";
import ContactSection from "../../Components/ContactSection/ContactSection";
export default function AboutContent() {
  return (
    <>
      <div className="About_Container">
        <AboutS1 />
        <AboutS2 />
        <AboutS3 />
      </div>
    </>
  );
}
function AboutS1() {
  return (
    <div className="AboutS1">
      <div className="AboutS1P1Main">
        <div className="AboutS1P1">
          <h5>About us</h5>
          <h1>
            Crafting Excellence <br /> in Every Stitch
          </h1>
          <div className="AboutS1P3">
            <a href={CompanyProfile} download="Company_Profile.pdf" className="HomeS4_P1_A">
              <button className="AboutS4_P1_B">
                Download Our Company Profile
                <img src={AboutS1P1BIcon} alt="" />
              </button>
            </a>
            <a href={CompanyCatalogue} download="Company_Catalogue.pdf" className="HomeS4_P1_A">
              <button className="AboutS4_P1_B">
                Download Our Educational Catalogue
                <img src={AboutS1P1BIcon} alt="" />
              </button>
            </a>
          </div>
        </div>
        <div className="AboutS1P2C">
          <div className="AboutS1P2">
            <span>Uniform Arena</span> is a local uniform factory owned by Al Awadi Enterprises Group which was established in 2016 in Qatar. <br /> <br />
            Successfully we launched our Qatari uniform brand using the best machinery available in the market with cutting-edge technology to maintain the
            highest quality standards to achieve customer satisfaction. <br /> We utilize our extensive knowledge of uniform-making helping schools dress their
            students with bespoke uniform manufacturing & design.
            <br />
            By providing customized and personalized services, we always ensure our top-quality services by placing the customers’ needs first and prioritizing
            their satisfaction.
          </div>
        </div>
      </div>
    </div>
  );
}

function AboutS2() {
  return (
    <>
      <div className="AboutS2">
        <div className="AboutS2P1">
          <h1>Quality That Speaks, Uniforms That Stand Out.</h1>
        </div>
        <div className="AboutS2P2">
          <div className="AboutS2P2B">
            <h1>our mission</h1>
            <p>manufacturing products comparable to international standards, through better quality, latest technology and continuous innovation.</p>
            <img src={AboutS2P2L} alt="" className="AboutS2P2Img" />
          </div>
          <div className="AboutS2P2B">
            <h1>our VISION</h1>
            <p>
              Maintaining a long-term leadership position in the uniforms manufacturing market. <br /> This will be achieved through operating efficiency,
              continued dedication to customer care, cost-effectiveness and innovation.
            </p>
            <img src={AboutS2P2R} alt="" className="AboutS2P2Img" />
          </div>
        </div>
      </div>
    </>
  );
}
function AboutS3() {
  return (
    <>
      <ContactSection />
    </>
  );
}

import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Blazers/B1.webp";
import Image2 from "../../../../Product/School_Uniforms/Blazers/B2.webp";
import Image3 from "../../../../Product/School_Uniforms/Blazers/B3.webp";
import Image4 from "../../../../Product/School_Uniforms/Blazers/B4.webp";
import Image5 from "../../../../Product/School_Uniforms/Blazers/B5.webp";
import Image6 from "../../../../Product/School_Uniforms/Blazers/B6.webp";
import Image7 from "../../../../Product/School_Uniforms/Blazers/B7.webp";

const BlazersSchool = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Blazers", arabic: "بلايزرات" }}
    />
  );
};

export default BlazersSchool;

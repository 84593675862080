import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/School_Uniforms/Jumper/J1.webp";
import Image2 from "../../../../Product/School_Uniforms/Jumper/j2.webp";
import Image3 from "../../../../Product/School_Uniforms/Jumper/j3.webp";
import Image4 from "../../../../Product/School_Uniforms/Jumper/J4.webp";
import Image5 from "../../../../Product/School_Uniforms/Jumper/J5.webp";
import Image6 from "../../../../Product/School_Uniforms/Jumper/J6.webp";

const JumperSchool = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  return (
    <ProductGallery
      images={images}
      category={{ english: "school_uniform", enName: "Educational", arabic: "التعليم", arName: "التعليم" }}
      product={{ english: "Jumper", arabic: "تيشرتات صوف" }}
    />
  );
};

export default JumperSchool;

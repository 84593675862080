import React from "react";
import ProductGallery from "../../GalleryImagesComponent";
import Image1 from "../../../../Product/Corporate/Security-Cor/Sec1.webp";
import Image2 from "../../../../Product/Corporate/Security-Cor/Sec2.webp";
import Image3 from "../../../../Product/Corporate/Security-Cor/Sec3.webp";
import Image4 from "../../../../Product/Corporate/Security-Cor/Sec4.webp";
import Image5 from "../../../../Product/Corporate/Security-Cor/Sec5.webp";
import Image6 from "../../../../Product/Corporate/Security-Cor/Sec6.webp";
import Image7 from "../../../../Product/Corporate/Security-Cor/Sec7.webp";

const SecurityCor = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  return (
    <ProductGallery
      images={images}
      category={{ english: "corporate", enName: "Corporate", arabic: "الشركات", arName: "الشركات" }}
      product={{ english: "Security", arabic: "ملابس أمنية" }}
    />
  );
};

export default SecurityCor;
